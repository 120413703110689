import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState
} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../components/button";
import Error from "../../components/error";
import FAQs from "../../components/faqs/faqs";
import Loading from "../../components/loading";
import Section from "../../components/section";
import {WebsiteFAQItem} from "../../models/web/WebsiteFaqItem";
import {FirebaseContext} from "../../provider/firebase";

type FAQProps = {
  location?: any;
};

const Faq: FC<FAQProps> = ({location}) => {
  const {firebase} = useContext(FirebaseContext);

  const [faqItems, setFaqItems] = useState<WebsiteFAQItem[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        setLoading(faqItems?.length === 0);
        setError(null);

        const faqsResult = await firebase
          .firestore()
          .collection("websiteFaqItemsNft")
          .get();
        if (faqsResult.empty) {
          setError("No NFT FAQs found");
        }
        setFaqItems(
          faqsResult.docs
            .map((faq) => faq.data() as WebsiteFAQItem)
            .sort(
              (a: WebsiteFAQItem, b: WebsiteFAQItem) => b.sortKey - a.sortKey
            )
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Only query the backend if the FAQs was not already loaded.
    // This is necessary to not fetch the FAQs again when navigating
    // back and forth.
    if (!faqItems?.length && firebase) {
      fetchFAQs();
    }
  }, [firebase]);

  const intl = useIntl();
  let previousPath = "/";
  if (location && location.state && location.state.previousPath) {
    previousPath = location.state.previousPath;
  }
  const onBackClick = () => {
    window.history.back();
  }
  if ((loading || !faqItems) && !error) {
    return <Loading />;
  } else if (error || !faqItems) {
    return <Error error={error} />;
  } else if (faqItems) {
    return (
      <>
        <div className="container">
          <Section
            anchorTag="content"
            title={intl.formatMessage({id: "faq"})}
            className="my-5"
            margin={false}
          >
            <FAQs
              location={location}
              faqs={faqItems}
              error={error}
              loading={loading}
            />
          </Section>
          <div className="row mb-5">
            <div className="col-12">
              <Button
                action={onBackClick}
                previousPath={previousPath}
                goBack
                className="float-right"
              >
                <FormattedMessage id="faq.back" />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Error error={"Unexpected state"} />;
  }
};

export default Faq;
